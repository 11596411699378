<template>
  <BasePending v-if="pending" />
  <Accordion v-else :activeIndex="[1]" :multiple="true">
    <AccordionTab header="Ajouter un établissement">
      <form class="p-field p-4" @submit.prevent="onSubmit">
        <div class="flex p-fluid">
          <div class="col-2">
            <label for="id">Unique Id</label>
            <InputText id="id" v-model="uniqueId" disabled />
          </div>
          <div class="col-10">
            <label for="name">Nom</label>
            <InputText
              id="name"
              v-model="name"
              @change="uniqueIdCallback($event)"
            />
          </div>
        </div>
        <div class="flex justify-content-end mt-4">
          <Button
            class="p-button-secondary p-button-text mr-2"
            icon="pi pi-times"
            label="Effacer"
            @click="clearInput"
            :disabled="!name || !uniqueId"
          />
          <Button
            type="submit"
            class="p-button-success mr-2"
            icon="pi pi-plus"
            label="Ajouter"
            :disabled="!name || !uniqueId"
          />
        </div>
      </form>
    </AccordionTab>
    <AccordionTab header="Etablissements">
      <DataTable
        ref="dt"
        class="p-datatable-sm"
        :value="centers"
        :paginator="true"
        :rows="rows"
        dataKey="id"
        :loading="pending"
        v-model:filters="filters"
        v-model:selection="selectedCenters"
        filterDisplay="row"
      >
        <Column
          selectionMode="multiple"
          style="text-align: center; width: 48px"
        />
        <Column
          filterField="id"
          header="Code"
          :showFilterMenu="false"
          style="width: 160px"
        >
          <template #filter>
            <small class="font-light">Unique</small>
          </template>
          <template #body="{ data }">
            <span :class="data.disabled ? 'disabled' : ''">{{ data.id }}</span>
          </template>
        </Column>
        <Column
          field="name"
          filterField="name"
          header="Nom"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @input="filterCallback()"
              class="p-column-filter"
              :placeholder="`Rechercher par nom`"
            />
          </template>
          <template #body="{ data }">
            <form @submit.prevent="onEdit(data)" id="form-row">
              <InputText
                v-model="data.name"
                :disabled="!data.edit"
                class="inplace"
                :class="data.disabled ? 'disabled' : ''"
              />
            </form>
          </template>
        </Column>
        <Column field="details" :showFilterMenu="false">
          <template #filter>
            <span class="text-sm font-light">Informations</span>
          </template>
          <template #body="{ data }">
            <div class="text-sm font-light">
              <p :class="data.disabled ? 'disabled' : ''">
                {{ data.details?.address }} - {{ data.details?.zipcode }}
                {{ data.details?.city }} <br />
                <i class="fa fa-phone" /> {{ data.details?.phonenumber }}
              </p>
            </div>
          </template>
        </Column>
        <Column filterField="id" :showFilterMenu="false" style="width: 160px">
          <template #filter>
            <small class="font-light">ID Easilys</small>
          </template>
          <template #body="{ data }">
            <span :class="data.disabled ? 'disabled' : ''">{{
              data.easilysSiteId
            }}</span>
          </template>
        </Column>
        <Column style="width: 120px" :showFilterMenu="false">
          <template #filter><small class="font-light">Actions</small></template>
          <template #body="{ data }">
            <Edit :data="data" v-if="!data.disabled" />
            <Button
              class="p-button-text"
              :class="data.disabled ? 'p-button-primary' : 'p-button-danger'"
              :label="data.disabled ? 'Reactiver' : ''"
              :icon="data.disabled ? '' : 'pi pi-ban'"
              @click="onDisable($event, data)"
            />
          </template>
        </Column>
      </DataTable>
    </AccordionTab>
  </Accordion>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "vue";
import useDatabase from "../../composables/useDatabase";
import { useToast } from "primevue/usetoast";
import { projectDatabase } from "@/firebase/config";
import { FilterMatchMode } from "primevue/api";
import { useConfirm } from "primevue/useconfirm";

import Edit from "./_Edit";

export default defineComponent({
  components: { Edit },
  setup() {
    const toast = useToast();
    const confirm = useConfirm();

    const { getAll } = useDatabase("centers");
    const { docs: centers, error, pending } = getAll();

    const newCenter = reactive({
      uniqueId: "",
      name: "",
    });

    const uniqueIdCallback = (event) => {
      newCenter.uniqueId = event.target.value
        .trim()
        .replace(/\s+/g, "")
        .toLowerCase();
    };

    const clearInput = () => {
      newCenter.name = "";
      newCenter.uniqueId = "";
    };

    const onSubmit = async () => {
      const dbRef = projectDatabase.ref(`centers/${newCenter.uniqueId}`);
      const isUnique = !(await dbRef.get()).exists();
      if (isUnique) {
        await dbRef.update({
          name: newCenter.name,
        });
        toast.add({
          severity: "success",
          summary: "Informations",
          detail: `Nouvel établissement ${newCenter.name} ajouté avec succès !`,
          life: 2500,
        });
        clearInput();
      } else {
        toast.add({
          severity: "error",
          summary: "Erreur",
          detail: `Cet établissement existe déjà`,
          life: 2500,
        });
      }
    };

    const selectedRow = ref(null);

    const onEdit = async (data) => {
      const dbRef = projectDatabase.ref(`centers/${data.id}`);
      const centerToEdit = await dbRef.get();
      if (centerToEdit.val().name !== data.name) {
        await dbRef.update({
          name: data.name,
        });
        toast.add({
          severity: "success",
          summary: "Informations",
          detail: `L'établissement a été renommé !`,
          life: 2500,
        });
      }
      data.edit = false;
    };

    const onDisable = async (event, data) => {
      const dbRef = projectDatabase.ref(`centers/${data.id}`);
      const centerToDisable = await dbRef.get();
      if (!centerToDisable.val().disabled) {
        confirm.require({
          target: event.currentTarget,
          message: `Désactiver l'établissement ${data.name}?`,
          rejectClass: "p-button-text p-button-secondary",
          acceptClass: "p-button-danger",
          acceptLabel: "Désactiver",
          accept: async () => {
            await dbRef.update({
              disabled: true,
            });
            data.disabled = true;
            toast.add({
              severity: "success",
              detail: `L'établissement ${
                centerToDisable.val().name
              } à été désactivé !`,
              life: 2500,
            });
          },
        });
      } else {
        await dbRef.update({
          disabled: null,
        });
        delete data.disabled;
        toast.add({
          severity: "success",
          detail: `L'établissement ${
            centerToDisable.val().name
          } à été réactivé !`,
          life: 2500,
        });
      }
    };

    const rows = ref(10);
    const filters = ref({
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const selectedCenters = ref([]);

    return {
      pending,
      centers,
      error,
      rows,
      filters,
      selectedCenters,
      ...toRefs(newCenter),
      onSubmit,
      onEdit,
      onDisable,
      uniqueIdCallback,
      clearInput,
      selectedRow,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.p-accordion-tab) {
  .p-accordion-content {
    padding: 0;
  }
}
</style>
