<template>
  <Button
    class="p-button-text p-button-secondary"
    icon="pi pi-pencil"
    @click="visible = true"
  />
  <Dialog
    modal
    :draggable="false"
    v-model:visible="visible"
    :style="{ minWidth: '50vw' }"
    :header="`Modifier ${data.name}`"
  >
    <form @submit.prevent="onSubmit" class="p-field p-fluid" id="form">
      <div class="p-fluid p-field col">
        <label v-required for="name">Nom</label>
        <InputText v-model="center.name"></InputText>
        <label for="easilysSiteId">Site ID Easilys</label>
        <InputText v-model="center.easilysSiteId"></InputText>
      </div>

      <div class="field-header">
        <h4>Informations</h4>
        <div class="hr"></div>
      </div>
      <div class="p-fluid p-field">
        <div class="col-12">
          <label for="address">Adresse</label>
          <InputText id="address" v-model="center.details.address"></InputText>
        </div>
      </div>
      <div class="flex">
        <div class="col-6">
          <label for="zipcode">Code Postal</label>
          <InputText id="zipcode" v-model="center.details.zipcode"></InputText>
        </div>
        <div class="col-6">
          <label for="city">Ville</label>
          <InputText id="city" v-model="center.details.city"></InputText>
        </div>
      </div>
      <div class="flex">
        <div class="col">
          <label for="phonenumber">Téléphone</label>
          <InputMask
            mask="99 99 99 99 99"
            id="phonenumber"
            v-model="center.details.phonenumber"
          ></InputMask>
        </div>
      </div>
    </form>
    <template #footer>
      <div class="flex justify-content-end">
        <Button
          label="Retour"
          class="p-button-text p-button-secondary"
          @click="visible = false"
        />
        <Button
          label="Enregistrer"
          class="p-button-success"
          type="submit"
          form="form"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { ref, watch } from "vue";
import { projectDatabase } from "@/firebase/config";
import { useToast } from "primevue/usetoast";

export default {
  props: ["data"],
  setup(props) {
    const toast = useToast();
    const visible = ref();

    const center = ref({});

    watch(visible, () => {
      center.value = {
        name: props.data.name,
        easilysSiteId: props.data.easilysSiteId,
        details: {
          address: props.data.details?.address,
          zipcode: props.data.details?.zipcode,
          city: props.data.details?.city,
          phonenumber: props.data.details?.phonenumber,
        },
      };
    });

    const onSubmit = async () => {
      const dbRef = projectDatabase.ref(`centers/${props.data.id}`);
      await dbRef.update(center.value);
      visible.value = false;
      toast.add({
        severity: "success",
        summary: "Informations",
        detail: `L'établissement ${center.value.name} à été modifié !`,
        life: 2500,
      });
    };

    return { visible, center, onSubmit };
  },
};
</script>

<style lang="scss" scoped>
#form {
  .field-header {
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    .hr {
      height: 1px;
      background-color: $secondary-light;
      flex-grow: 1;
      transform: translateY(-4px);
      margin-left: 0.3rem;
    }
  }
}
</style>
